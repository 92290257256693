@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');
body{
    font-family: 'Inter' !important;
    background-color: rgb(243, 241, 241) !important;
}
.brand-title{
    width: max-content;
}
.brand-title a{
    color: rgb(67, 72, 104);
}
.brand-title a:hover,.brand-title a:visited{
    color: rgb(67, 72, 104);
}
.Toastify__toast-theme--light{
    font-weight: 500;
}
.sidebar-link{
    text-decoration: none;
    color: rgb(75, 75, 75) !important;
}
.sidebar-link:hover{
    background-color: #cdced3;
    color: rgb(25, 26, 29) !important;
}
.heading-border-bottom{
    border-bottom: 1px solid rgb(182, 182, 182);
    margin-bottom: 40px;
    padding-bottom: 5px;
}
.model-testing-heading a{
    color: rgb(77, 100, 255);
    text-decoration: none;
}
.hover-pointer:hover{
    cursor: pointer;
}
.model-testing-heading{
    font-size: 18px;
    padding: 10px;
    border-radius: 5px;
    background-color: rgb(230 230 230);
    box-shadow: 0px 0px 5px -3px rgb(59, 59, 59);
    margin-bottom: 40px;
}
.model-testing-heading a:hover{
    color: rgb(40, 40, 68) !important;
}
.model-item{
    color: black;
    background-color:rgb(210, 220, 255) ;
    border-radius: 5px;
    border-bottom: 1px solid rgba(131, 140, 189, 0.685) ;
}
.model-item:hover{
    background-color: #4e58e1;
    color: white !important;
}
.model-item a{
    color: black;
}
.model-item:hover a{
    color: white;
}

.model-item a:hover{
    text-decoration: underline;
}
.model_files_folder{
    margin-left:10px;
    width: max-content;
    color: #8b93ff;
}
.model_files_folder:hover{
    cursor: pointer;
    color: #707aff;
    background-color: rgb(255, 255, 255);
    /* color: rgb(25, 26, 29); */
}
.file-item{
    background-color: #eeeeee;
}
.file-item:hover{
    cursor: pointer;
    background-color: rgb(219, 219, 219);
}
.MuiFormLabel-root{
    margin-bottom: 4px;
}
.MuiFilledInput-input{
    margin-top: 4px !important;
    padding: 10px !important;
}
.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
.model-file-details{
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1.5rem;
    background-color: rgb(210, 220, 255);
    justify-content: space-between;
    border-radius: 0.3rem;
    border-bottom: 1px solid rgb(121, 121, 121) ;
}
.model-file-details:hover{
    cursor: pointer;
    background-color:#4e58e1;
    color: white !important;
}
.model-metrics-button {
    color: white !important;
}
.model-metrics-button:hover{
    /* background-color : #ffffff; */
    color: white !important;
}

.model-details-page-test-button{
    /* padding: 0rem 0rem !important; */
    /* height: 2rem; */
    /* border: 2px solid !important; */
    /* outline-width: 2px ; */
    /* outline: 2px solid #4e58e1 !important; */
    /* border-image-slice: 1 !important; */
    background-color: #9398e4 !important;
    color: rgb(255, 255, 255) !important;
    /* font-weight: 500 !important; */
    /* border-radius: 5px !important; */
    /* border-width: 2px !important; */
    /* border-image-source: linear-gradient(to left, #743ad5, #d53a9d) !important;      */
}
/* .model-details-page-test-button  :hover{
    background-color: #4e58e1 !important;
    color: white !important;
} */
.styles-module_wrapper__1I_qj{
    z-index: 10001 !important;
    display: flex;
    align-items: center;
    position: absolute;
    padding: 0px 60px 0px 60px;
    left: 0;
    top: 0;
    /* width: 100%; */
    /* height: 100%; */
    width: 100vw !important;
    height: 100vh !important;
    background-color: #000000d6 !important;
    box-sizing: border-box;
}


.input{
    margin: 0.5rem 0rem;
    outline: none;
    padding: 0.65rem;
    background-color: #eef1f4;
    outline-width: none;
    border: 2px solid transparent;
    border-radius: 5px;
}

.input:focus{
    border-color: #4e58e1;
}

.font-sm{
    font-size: 0.8rem;
}
.test_model_link {
    transition: none !important;
} 

.test_model_link:hover a{
    color:white !important;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
    
}

.hide-scrollbar{
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
}

.labelling-page__preview_image{
    border: 2px solid #acacac;
    border-radius: 5px ;
}
.labelling-page__selected_image{
    border: 2px solid #6584ff !important;
    border-radius: 5px ;
    box-shadow: 0px 0px 10px 1px #b0b0b0;
}
.labelling-page__preview_image:hover{
    cursor: pointer;
    border: 2px solid #6584ff !important;
    border-radius: 5px ;
    box-shadow: 0px 0px 10px 1px #bfbfbf;
}
.model_training_metrics__donwnload_model_link{
    margin-left: 1.5rem;
    color: #4e58e1;
    text-decoration: none;
}
.model_training_metrics__donwnload_model_link:hover{
    color: #4d58ed;
    text-decoration: underline;
    cursor: pointer;
}