.ocr_results_text{
    margin: 0.5rem;
    background-color: rgb(233, 232, 232);
    padding: 3px 5px;
    border-radius: 5px;
    min-width: 100%;
    box-shadow: 0px 0px 10px -4px rgb(129, 129, 129);
}
.ocr_results__item{
    display: flex;
    margin: 0.5rem;
    background-color: rgb(255, 255, 255);
    padding: 1rem;
    border-radius: 10px;
    border-bottom: 2px solid rgb(207, 205, 214);
}
.model_details_page_menu_item{
    color: black !important;
    transition: none !important;
}
.model_details_page_menu_item:hover{
    background-color: rgb(91, 121, 255) !important;
    color: white !important;
}
.chart-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.single-chart-container{
    
    margin: 1.5rem;
    padding:0.3rem;
    min-width: 400px;
    max-width: 400px;
    background-color: white;
    border-radius: 5px;
    box-shadow:0px 0px 5px -2px;

}

label{
    color: rgb(45, 44, 44) !important;
}