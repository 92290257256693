.filepond--item {
    width: calc(10% - 0.5em);
}
.filepond--wrapper {
    /* height: 40vh !important; */
    border-radius: 10px;
}
.filepond--drop-label{
    background-color: white;
    /* height: 40vh !important; */
}